<template>
  <span class="tooltip-container">
    <Tooltip
      class="clicable"
      icon="fa"
      :title="details"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="onClick"
      :class="
        pressed || (hover && firstPendingCommand)
          ? 'fa-trash text-danger icon-padding'
          : 'fa-refresh fa-spin'
      "
      :if="!pressed"
    />
  </span>
</template>

<script>
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "PendingCommandIndicator",
  props: {
    entry: {
      type: Object,
      required: true
    },
    editor: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      hover: false,
      pressed: false
    };
  },
  components: {
    Tooltip
  },
  computed: {
    details() {
      if (this.pressed) return "";
      if (!this.pendingCommands.length) return "";
      let command = this.firstPendingCommand;
      let html = "";
      html += `${this?.entry?.name}<br>${this.$t("has_pending_commands")}:`;
      html +=
        "<table style='background-color: inherit;opacity: 1;white-space:nowrap;'><tbody>";
      html += `<tr>`;
      html += `<td style="max-width:150px;">${this.$t("value")}</td>`;
      html += `<td>${this.$t("datetime")}</td>`;
      html += `<td>${this.$tc("sent", 1)}</td>`;
      html += `</tr>`;
      (this.pendingCommands || []).forEach((i) => {
        html += `<tr class="${i.id == command?.id ? "bg-red" : ""}">`;
        html += `<td style="max-width:150px;overflow:hidden;padding:0 5px;">${i.writing_value}</td>`;
        html += `<td>${this.$dt.format(i.created_datetime)}</td>`;
        html += `<td title="${i.processed_datetime}">${
          i.processed_datetime
            ? '<i class="fa fa-check"></i>'
            : "command_state_id" in i
            ? '<i class="fa fa-refresh fa-spin"></i>' // regular command
            : '<i class="fa fa-share-alt"></i>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-refresh fa-spin"></i>' // mapping data command
        }</td>`;
        html += `</tr>`;
      });
      html += "</tbody></table>";
      return html;
    },
    pendingCommands() {
      let lst = JSON.parse(JSON.stringify(this?.entry?.pending_commands || []));
      // add mapping command to the view
      if (this?.entry?.pending_mapping_write) {
        lst.push(
          JSON.parse(JSON.stringify(this?.entry?.pending_mapping_write))
        );
      }
      lst.sort((a, b) => {
        if (a.created_datetime > b.created_datetime) return -1;
        if (b.created_datetime > a.created_datetime) return 1;
        return 0;
      });
      return lst;
    },
    firstPendingCommand() {
      return this.pendingCommands.find((i) => i.command_state_id == 1);
    }
  },
  methods: {
    remove() {
      let command = this.firstPendingCommand;
      let payload = {
        id: command.id,
        cancel_command: true
      };
      this.$store.dispatch("command/save", [payload]).then((result) => {
        if (result && result.length) {
          if (typeof result[0] == "string") {
            this.$swal({
              title: this.$t("item_could_not_be_deleted"),
              text: result[0],
              icon: "error"
            });
          } else {
            // begin - ToDo: move it to the command module
            let lst = this.$store.getters["dashboard/dataList"] || [];
            let data = lst.find((i) => i.id == this.entry.id);
            if (data) {
              let entry = {
                data_id: data.id,
                pending_commands: (data.pending_commands || []).filter(
                  (i) => i.id != command.id
                )
              };
              this.$store.dispatch("dashboard/setDataValue", entry);
            }
            this.$emit("commandRemoved", command.id);
            // end
            let msg = this.$t("you_have_deleted_n_items", { count: 1 });
            this.$toasted.show(msg, {
              position: "bottom-right",
              duration: 5000,
              iconPack: "fontawesome",
              icon: "fa-check",
              type: "success",
              action: {
                icon: "fa-close",
                onClick: (e, me) => {
                  me.goAway(0);
                }
              }
            });
          }
        }
      });
    },
    onClick() {
      let command = null;
      if (this.$can("manage", "DadoEscrita")) {
        command = this.firstPendingCommand;
      }
      if (command) {
        let self = this;
        this.pressed = this;
        let message = this.$t("removal_pending_command");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = "";
        field_name += `${this.$t("value")}: ${command.writing_value}`;
        if (field_name.length > 40) {
          field_name = field_name.substring(0, 36) + "...";
        }
        field_name += ` <br> ${this.$t("created_at")}: ${this.$dt.format(
          command.created_datetime
        )}`;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p><div class="text-warning"><i class="${cls}"></i> ${text}</div><br/></p>`;
        let html = "";
        html += `${message}<br/><b>${field_name}</b>${warning}`;
        let wrapper = document.createElement("div");
        wrapper.innerHTML = html;

        self
          .$swal({
            title: self.$t("are_you_sure"),
            content: wrapper,
            icon: "warning",
            buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
          })
          .then((confirm) => {
            self.pressed = false;
            if (confirm) {
              self.remove();
            }
          });
        return;
      } else if (this.editor) {
        this.$emit("click");
      }
    }
  }
};
</script>

<style scoped>
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.icon-padding {
  padding-left: 1px; /* spin and trash does no share same room*/
}

.tooltip-icon {
  margin: 0;
}

.tooltip-container::v-deep > .tooltip > .tooltip-inner {
  max-width: inherit;
}
</style>
