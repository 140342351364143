<template>
  <div class="me">
    <span v-if="isToggle">
      <button
        v-if="saveBtn"
        class="btn btn-xs"
        style="vertical-align: top"
        @click.stop.prevent="resetValue"
        :title="
          $t(
            control.readOnly
              ? 'titles.data_not_allows_writing'
              : saveBtn
              ? 'close'
              : 'titles.reset_value'
          )
        "
      >
        <i :class="saveBtn ? 'fa fa-close' : 'fa fa-undo'"></i>
      </button>
      <FormInputToggle
        labels="on,off"
        fontSize="16"
        v-model="value"
        :colors="busy ? '#a0a0a0,#a0a0a0' : '#000,#a0a0a0'"
        :disabled="busy || control.readOnly"
        :title="
          $t(
            control.readOnly
              ? 'titles.data_not_allows_writing'
              : 'titles.change_data_value'
          )
        "
        ref="inp"
      ></FormInputToggle>
    </span>

    <div
      v-else
      class="input-group input-group-sm"
      :class="{ 'has-error': errMsg != '' }"
    >
      <div
        v-if="undoBtn == 'left'"
        class="input-group-addon btn btn-default"
        :title="
          $t(
            control.readOnly
              ? 'titles.data_not_allows_writing'
              : saveBtn
              ? 'close'
              : 'titles.reset_value'
          )
        "
        :disabled="busy || control.readOnly"
        v-on:click.stop.prevent="resetValue"
      >
        <i :class="saveBtn ? 'fa fa-close' : 'fa fa-undo'"></i>
      </div>
      <div v-else class="input-group-addon text-gray">
        <i
          class="fa fa-lock"
          :title="$t('titles.data_not_allows_writing')"
          v-if="control.readOnly"
        ></i>
        <i
          class="fa fa-unlock"
          :title="$t('titles.data_allows_writing')"
          v-else
        ></i>
      </div>
      <template v-if="text_list">
        <select
          class="form-control"
          v-model="value"
          :title="(entry.current_value && entry.current_value.value) || ''"
          :disabled="busy || control.readOnly"
          ref="inp"
          style="padding-left: 0"
        >
          <option
            v-for="(item, ix) in text_list_items"
            v-bind:key="ix"
            v-bind:value="item.id"
          >
            {{ item.name }} ({{ item.id
            }}<template v-if="defaultTextListValue == item.id"
              >&#x2713;</template
            >)
          </option>
        </select>
      </template>
      <template v-else>
        <DataValueInputDuration
          v-if="isDuration"
          v-model="value"
          :title="$t(title)"
          :disabled="busy || control.readOnly"
          :focus="saveBtn"
          :showDays="showDays"
          :showSeconds="showSeconds"
          :userUnit="unitLabel || 'seconds'"
          @blur="save"
          @keydown.enter.prevent="save"
        />
        <InputString
          v-else-if="isString"
          v-model="value"
          class="form-control text-left"
          :class="control.readOnly ? 'disabled' : ''"
          :focus="saveBtn"
          :disabled="busy || control.readOnly"
          :title="$t(title)"
          :maxSize="maxSize"
          @keydown.enter.prevent="save"
          @keydown.esc.prevent="resetValue"
          @keydown.prevent.tab.exact="tab"
          @keydown.prevent.shift.tab="shiftTab"
        />
        <InputNumber
          v-else
          v-model="value"
          class="form-control text-right"
          :class="control.readOnly ? 'disabled' : ''"
          :focus="saveBtn"
          :disabled="busy || control.readOnly"
          :title="$t(title)"
          @keydown.enter.prevent="save"
          @keydown.esc.prevent="resetValue"
          @keydown.prevent.tab.exact="tab"
          @keydown.prevent.shift.tab="shiftTab"
        />
      </template>
      <div
        v-if="undoBtn == 'right'"
        class="input-group-addon btn btn-default"
        :title="
          $t(
            control.readOnly
              ? 'titles.data_not_allows_writing'
              : saveBtn
              ? 'close'
              : 'titles.reset_value'
          )
        "
        v-bind:disabled="control.readOnly"
        @click.stop.prevent="resetValue"
      >
        <i :class="saveBtn ? 'fa fa-close' : 'fa fa-undo'"></i>
      </div>
      <div
        v-if="saveBtn"
        class="input-group-addon btn btn-default"
        :title="$t('save')"
        v-bind:disabled="control.readOnly"
        v-on:click.prevent.stop="save"
      >
        <i class="fa fa-check"></i>
      </div>
    </div>
  </div>
</template>

<script>
import DataValue from "@/components/data-value.vue";
import FormInputToggle from "@/components/registration/form-input-toggle.vue";
import InputNumber from "@/components/input-number.vue";
import InputString from "@/components/input-string.vue";
import DataValueInputDuration from "@/components/data-value-input-duration.vue";
export default {
  name: "DataValueInput",
  extends: DataValue,
  components: {
    FormInputToggle,
    InputNumber,
    InputString,
    DataValueInputDuration
  },
  props: {
    control: {
      type: Object,
      required: true
    },
    undoBtn: {
      type: String,
      required: false,
      default: () => "right"
    },
    saveBtn: {
      type: Boolean,
      required: false,
      default: () => false
    },
    enableSave: {
      type: Boolean,
      required: false,
      default: () => true
    },
    enableTab: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      value: "",
      busy: false,
      errMsg: ""
    };
  },
  watch: {
    value(n, o) {
      if (
        this.isToggle &&
        this.saveBtn &&
        (o !== "" || this.dataValue === "")
      ) {
        this.save();
      } else {
        this.errMsg = this.validate(parseFloat(n));
        this.$emit("input", n);
      }
    }
  },
  computed: {
    title() {
      return (
        this.errMsg ||
        (this?.control?.readOnly
          ? "titles.data_not_allows_writing"
          : "titles.insert_data_value")
      );
    },
    isToggle() {
      return this.control.type.match(/(bool)/gi) != null;
    },
    isDuration() {
      return this?.dataFormat?.format_mask === "duration";
    },
    isString() {
      return this.control.type.match(/(string)/gi) != null;
    },
    showDays() {
      return this.isDuration && /dd/i.test(this?.entry?.custom_format || "");
    },
    showSeconds() {
      return this.isDuration && /ss/i.test(this?.entry?.custom_format || "");
    },
    unitLabel() {
      return this?.entry?.unity_label || "";
    },
    allowDecimal() {
      return this.control.type.match(/(float)/gi) != null;
    },
    allowNegativeInput() {
      return !(
        this.control.dataType.match(/(unsigned|bool|coil|input status)/gi) !=
        null
      );
    },
    maxSize() {
      return parseInt(this?.control?.maxSize || 0);
    }
  },
  methods: {
    resetValue: function () {
      if (!this.control.readOnly) {
        if (this.saveBtn) {
          this.$emit("undo");
        } else {
          this.value = this.dataValue;
        }
      }
    },
    save() {
      this.tab();
      if (!this.enableSave) return;

      this.busy = true;
      setTimeout(() => {
        this.$emit("save", this.value);
        this.busy = false;
      }, 500);
    },
    tab() {
      if (!this.enableTab) return;
      let $e = document.activeElement;
      let inputSet =
        ($e && ($e.closest("div.box") ?? $e.closest("tbody"))) || null;
      if (inputSet) {
        let allInputs = Array.from(inputSet.querySelectorAll("input"));
        let nextIndex = allInputs.indexOf($e);
        if (nextIndex >= allInputs.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex++;
        }
        allInputs[nextIndex].focus();
        allInputs[nextIndex].select();
      }
    },
    shiftTab() {
      if (!this.enableTab) return;
      let $e = document.activeElement;
      let inputSet =
        ($e && ($e.closest("div.box") ?? $e.closest("tbody"))) || null;
      if (inputSet) {
        let allInputs = Array.from(inputSet.querySelectorAll("input"));
        let nextIndex = allInputs.indexOf($e);
        if (nextIndex == 0) {
          nextIndex = allInputs.length - 1;
        } else {
          nextIndex--;
        }
        allInputs[nextIndex].focus();
        allInputs[nextIndex].select();
      }
    }
  },
  created() {
    this.value = this.dataValue;
  },
  mounted() {
    if (this.saveBtn && this.$refs.inp && "focus" in this.$refs.inp) {
      this.$nextTick(() => {
        this.$refs.inp.focus();
      });
    }
  }
};
</script>

<style lang="css" scoped>
.me {
  display: inline-block;
  width: 100%;
}

.has-feedback .form-control {
  padding-right: 60px;
  background-color: transparent;
}

.feedback-text {
  right: 62px !important;
  font-size: 75%;
  text-align: left;
}

.errmsg {
  color: #b1b0b0;
}
</style>
