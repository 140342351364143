<template>
  <section>
    <!-- /Search -->
    <EquipmentSearchPanel :source="source" :searchEnabled="false" />
    <!-- -->
    <div class="text-center" v-if="!isReady" style="padding: 10px">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <div class="result-table" v-else-if="equipmentList && equipmentList.length">
      <EquipmentAlarmSearchBar
        :alarmList="alarmList"
        :panel="panel"
        @filtered="onFilteredAlarmListId"
      />
      <EquipmentAlarmConfigurationDisplay
        :alarmList="alarmList"
        :filteredAlarmIdList="selected"
        :panelOptions="panelOptions"
        :busy="busy"
        v-if="panelOptions"
      />
      <InfoBox
        v-else
        :title="$t('nothing_here')"
        :text="$t('there_are_invalid_panels_on_this_screen')"
        alert="default"
      />
    </div>
    <!-- -->
  </section>
</template>

<script>
import EquipmentSearchBase from "@/views/private/EquipmentSearchBase.vue";
import EquipmentAlarmConfigurationDisplay from "@/components/equipment-alarm-configuration-display.vue";
import EquipmentAlarmSearchBar from "@/components/equipment-alarm-search-bar.vue";
import InfoBox from "@/components/info-box.vue";
import { defAlarmPanel } from "@/components/control-sidebar/property-editors/detail-form-alarm.vue";

export default {
  name: "EquipmentSearchAlarmTable",
  extends: EquipmentSearchBase,
  components: {
    EquipmentAlarmConfigurationDisplay,
    InfoBox,
    EquipmentAlarmSearchBar
  },
  props: {
    filterCriteria: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      busy: false,
      customPanel: undefined,
      defAlarmPanel: defAlarmPanel().template,
      selected: []
    };
  },
  computed: {
    panel() {
      return this.customPanel || this.defAlarmPanel || null;
    },
    query() {
      return this.$store.getters["equipment/query"];
    },
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    showBanner() {
      let self = this;
      return self.$root.config.equipment_selection &&
        "show_banner" in self.$root.config.equipment_selection
        ? self.$root.config.equipment_selection.show_banner
        : false;
    },
    customConfiguration() {
      // return {
      //   panel_name: "57b05bb0-c417-4701-9e1d-28765578b8f8",
      //   path:
      //     "https://dev-telemetria-static.s3.amazonaws.com/media/4b1a3e9d-47f3-4aa4-bc7c-9db540884329/2e296f76-153b-4c4b-82d2-1c23d4000b49/screens/52f20932-44b4-47b5-865d-80ef81c9e9db.json",
      //   screen_id: "681"
      // };
      return (
        (this?.contract?.portal_data?.search_page || {})[this.$options.name] ||
        null
      );
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || null;
    },
    alarmList() {
      return this.$store.getters["dashboard/alarmList"] || [];
    },
    filteredAlarms() {
      let lst = this.alarmList;
      if (this?.query?.query_alarm) {
        lst = lst.filter((alarm) => alarm?.alarm_current_state?.state);
      } else {
        if (
          this?.query?.query_connection?.checked != "all" ||
          this?.query?.query_process_area?.id
        ) {
          if (this.equipmentList.length) {
            let cids = this.equipmentList.map(({ id }) => id);
            lst = lst.filter(
              ({ connector_id }) => cids.indexOf(connector_id) >= 0
            );
          }
        }
      }
      return lst;
    },
    panelOptions() {
      let entry = this?.customPanel?.options || this?.defAlarmPanel?.options;
      if (entry) {
        entry.filter.items = entry.filter.items.filter(
          ({ name }) => name != "alarmed"
        );
        entry.showStringSearch = false; // it avoids the default connector search
        entry.pagination = true; // at home, always paginate - no matter the user configuration
        entry.historyPanel = false; // at home, always disable the history panel - no matter the user configuration
        if (!this?.customPanel?.options) {
          entry.allAlarms = true; // since user did not create a alarm panel, overwrite the original asset setting all alarms
          let f = entry.fields.find(({ name }) => name == "connector_name");
          if (f) {
            f.visible = true;
          }
        }
      }
      return entry;
    },
    panelDataIdList() {
      return this.allAlarms
        ? []
        : (this?.panelOptions?.dataList || [])
          .filter(({ checked }) => checked)
          .map(({ data_id }) => data_id);
    },
    allAlarms() {
      return this?.panelOptions?.allAlarms || false;
    },
    canShowAlarmList() {
      return this.allAlarms && this.mode == "editor" ? false : true;
    },
    nContractAlarms() {
      return this?.contract?.registered_alarms || 0;
    },
    nAlarms() {
      // usable connector instances (does not consider any model)
      return this.alarmList.length;
    }
  },
  watch: {
    customConfiguration: {
      handler(n) {
        if (n) {
          this.updateCustomView();
        } else {
          this.defAlarmPanel = JSON.parse(
            JSON.stringify(defAlarmPanel().template)
          );
        }
      },
      deep: true,
      immediate: true
    },
    screens: {
      handler(n) {
        if (n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onFilteredAlarmListId(lst) {
      this.$set(this, "selected", lst);
    },
    customTemplate() {
      return (
        this.$store.getters["dashboard/template"](
          this.customConfiguration.screen_id
        ) || null
      );
    },
    setCustomPanel(template) {
      if (!this.customPanel) {
        let panel =
          ((template && template?.panels) || []).find(
            ({ name }) => name == this.customConfiguration.panel_name
          ) || null; // important - default null would render the default layout
        this.$set(this, "customPanel", panel);
      }
    },
    async updateCustomView() {
      if (
        this?.customConfiguration &&
        this.customPanel === undefined &&
        (this.screens || []).some(
          ({ id }) => id == this?.customConfiguration.screen_id
        )
      ) {
        // console.log("updateCustomView");
        let template = this.customTemplate();
        if (template) {
          this.setCustomPanel(template);
        } else {
          await this.$store
            .dispatch(
              "dashboard/fetchTemplate",
              this.customConfiguration.screen_id
            )
            .then((response) => {
              if (response) {
                this.setCustomPanel(response);
              }
            });
        }
      }
    },
    fetchAlarmList() {
      if (
        this.busy ||
        !this.canShowAlarmList ||
        (this.nAlarms && this.nAlarms >= this.nContractAlarms)
      ) {
        return;
      }
      var query = null;
      if (this?.panelDataIdList?.length) {
        query = {
          resource: "alarm",
          forceUpdate: true
        };
        let connectorIds = this.panelDataIdList
          .filter((id) => /connector_\d+_id/.test(id))
          .map((id) => id.match(/\d+/)[0]);
        if (connectorIds.length == 0) {
          query.data_ids = this.panelDataIdList;
        } else if (connectorIds.length == 1) {
          query.connector_id = connectorIds[0];
        } else {
          // TODO: validate if alarm API supports multiple connectors
          query.connector_ids = connectorIds;
        }
      } else {
        query = {
          resource: "alarm",
          base_model: false,
          forceUpdate: true
        };
      }
      if (!query) return;
      this.busy = true;
      this.$store.dispatch("dashboard/fetchResourcesFrom", query).then(() => {
        this.busy = false;
      });
    }
  },
  created() { },
  mounted() {
    this.refreshEnabled = true;
    this.fetchAlarmList();
  }
};
</script>

<style scoped>
.tab-search {
  padding-bottom: 200px !important;
}
.equipment-item {
}
.equipment-item:hover {
  cursor: pointer;
}
.equipment-item .info-box {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.equipment-item .info-box:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.equipment-enabled {
  opacity: 1;
}

.result-table {
  min-height: 400px;
}
</style>
