<template>
  <section>
    <div class="modal fade in" ref="alarmEditorForm">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" v-if="isReady">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ title }}</h4>
          </div>
          <div class="modal-body" style="min-height: 350px;">
            <div class="overlay overlay-local" v-if="busy">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
            <EquipmentAlarmConfigurationTable
              v-bind:alarms="alarms"
              v-bind:panelOptions="{ showLine: false }"
              v-bind:full="false"
            >
            </EquipmentAlarmConfigurationTable>
            <form action="">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="limit" class="">{{ $t("limit") }}</label>
                  <input
                    type="number"
                    id="limit"
                    name="limit"
                    class="form-control"
                    v-model="limit"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label
                    class="radio-label-block"
                    for="acknowledgement_enabled"
                    >{{ $t("acknowledgement_enabled") }}</label
                  >
                  <div class="radio-inline">
                    <input
                      type="radio"
                      name="yes"
                      id="yes"
                      class="form-check-input"
                      v-bind:checked="acknowledgement_enabled"
                      v-on:change="acknowledgement_enabled = true"
                    />
                    <label for="yes" class="form-check-label">{{
                      $t("yes")
                    }}</label>
                    <input
                      type="radio"
                      name="no"
                      id="no"
                      class="form-check-input"
                      v-bind:checked="!acknowledgement_enabled"
                      v-on:change="acknowledgement_enabled = false"
                    />
                    <label for="no" class="form-check-label">{{
                      $t("no")
                    }}</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default"
              v-on:click.stop.prevent="hide"
              v-bind:disabled="busy"
            >
              {{ $t("cancel") }}
            </button>
            <button
              class="btn btn-primary"
              v-on:click.stop.prevent="save"
              v-bind:disabled="busy"
            >
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import EquipmentAlarmConfigurationTable from "@/components/equipment-alarm-configuration-table";
import EquipmentAlarm from "@/services/equipment-alarm.js";
import MixinAlert from "@/project/mixin-alert.js";
export default {
  name: "EquipmentAlarmEditorForm",
  components: {
    EquipmentAlarmConfigurationTable
  },
  props: {
    alarms: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  mixins: [MixinAlert],
  data: function() {
    return {
      busy: false,
      isReady: true,
      limit: 0,
      acknowledgement_enabled: false
    };
  },
  computed: {
    title: function() {
      return this.$t("alarm_configuration");
    },
    alarm: function() {
      return (this.alarms && this.alarms.length && this.alarms[0]) || null;
    },
    isValid: function() {
      let self = this;
      return self.alarm != null;
    }
  },
  methods: {
    hide: function() {
      $(this.$refs.alarmEditorForm).modal("hide");
    },
    save: function() {
      let self = this;
      self
        .validatePrivileges("view", "EquipmentAlarmEditorForm")
        .then((isOk) => {
          if (!isOk) {
            self.hide();
            return;
          }
          if (self.isValid) {
            self.busy = true;
            let srv = new EquipmentAlarm();
            var payload = {
              limite: self.limit,
              habilita_reconhecimento: self.acknowledgement_enabled
            };
            srv.saveAlarm(self.alarm, payload).then(function(ret) {
              self.busy = false;
              if (self.validateSaveResponse(ret)) {
                self.$emit("refresh");
                self.hide();
              } else {
                self.hide();
              }
            });
            return;
          }
          self.hide();
        });
    },
    initData: function() {
      let self = this;
      self.acknowledgement_enabled =
        self.alarm.habilita_reconhecimento || false;
      self.limit = self.alarm.limite || 0;
    }
  },
  created: function() {
    this.initData();
  },
  mounted: function() {
    let self = this;
    $(self.$refs.alarmEditorForm)
      .on("hide.bs.modal", function() {
        self.showAlert(function() {
          self.$emit("hide");
        });
      })
      .on("shown.bs.modal", function() {
        self.$emit("show");
      })
      .modal("show");
  }
};
</script>
<style scoped>
form {
  text-align: left;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #bababa;
  padding: 20px 50px;
}
.radio-inline {
  display: inline-block;
  margin-right: 10px;
}
.radio-inline:hover {
  cursor: pointer;
}
.radio-label-block {
  display: block;
  margin-bottom: 10px;
}
.radio-label {
  color: gray;
  margin-right: 10px;
  margin-bottom: 10px;
}
.form-check-label {
  margin-right: 50px;
  margin-left: 0;
}
.form-check-label:hover {
  cursor: pointer;
}
.modal-footer > button {
  margin-right: 15px;
}
</style>
