<script>
import ConnectorService from "@/services/connector.js";
import FormFooterToolbar from "@/components/registration/form-footer-toolbar.vue";
import MixinAlert from "@/project/mixin-alert.js";
export default {
  name: "FormBase",
  mixins: [MixinAlert],
  components: {
    FormFooterToolbar
  },
  data() {
    return {
      ready: false,
      no_data: false,
      busy: false,
      cloneEnabled: false,
      groups: [],
      rule: ""
    };
  },
  computed: {
    connector() {
      return null; // it must be re-implemented by child
    },
    isModelBased() {
      return this.connector && this.connector.base_model_id ? true : false;
    },
    isModel() {
      return this.connector && this.connector.base_model ? true : false;
    },
    processAreas() {
      return this.$store.getters["processArea/processAreas"];
    },
    contract() {
      return this.$store.getters["user/contract"];
    },
    notification_channels() {
      let lst = this.$root.config.notification_channels || null;
      if (!lst) {
        // default communication channel
        lst = [
          {
            enabled: true,
            input_type: "email",
            placeholder: "email",
            data_source: {
              default_notifications: {
                attr: "emails",
                title: this.$t("users_to_be_notified_by_email"),
                text: "email"
              }
            }
          }
        ];
      }
      let contract = this.contract || null;
      return lst.filter(function(channel) {
        return (
          (channel.enabled && !channel.rule) ||
          !contract ||
          (channel.rule in contract && contract[channel.rule]) ||
          false
        );
      });
    },
    payload_notifications() {
      //notifications
      //https://hitecnologia.bitrix24.com.br/extranet/workgroups/group/47/tasks/task/view/16886/
      let self = this;
      let payload = {};
      if (self.groups && self.groups.length) {
        for (var i in self.groups) {
          var group = self.groups[i];
          payload[group.data_source] =
            group.data_source in payload ? payload[group.data_source] : {};
          payload[group.data_source][group.attr] = group.tags || [];
        }
      }
      return payload;
    }
  },
  methods: {
    onButtonCancelClick() {
      this.close("cancel");
    },
    onButtonSaveClick(stay, addAnother) {
      this.validatePrivileges("manage", this.rule).then((isOk) => {
        if (isOk) {
          if (
            this.isValid &&
            (!this.$refs.form || this.$refs.form.reportValidity())
          ) {
            this.save(stay, addAnother);
          }
        }
      });
    },
    onButtonCloneClick() {
      this.validatePrivileges("manage", this.rule).then((isOk) => {
        if (isOk) {
          if (this.isValid) {
            this.clone(false);
          }
        }
      });
    },
    onButtonRemoveClick() {
      let self = this;
      self.validatePrivileges("manage", this.rule).then((isOk) => {
        if (isOk) {
          self
            .$swal({
              title: self.$t("are_you_sure"),
              content: self.removalMessage,
              icon: "warning",
              buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
            })
            .then(function(isConfirm) {
              if (isConfirm) {
                self.remove(); // child must implement it
              }
            });
        }
      });
    },
    doRemove: function(payload, srv) {
      let self = this;
      self.busy = true;
      srv.remove(payload).then((ret) => {
        self.busy = false;
        self.$store.dispatch("fetchEquipmentList");
        if (self.validateDeleteResponse(ret)) {
          self.exitSuccess = true;
          self.$store.dispatch("user/configureUserContract");
          self.$store.dispatch("dashboard/removeResources", [payload.id]);
          self.showAlert(() => self.close("remove"));
        } else {
          self.showAlert();
        }
      });
    },
    initGroups(entry, source) {
      let self = this;
      let groups = [];
      for (var i in self.notification_channels) {
        var channel = JSON.parse(JSON.stringify(self.notification_channels[i]));
        channel.name = "";
        channel.fixed = true;
        channel.tags = [];
        if (channel.channel == "email") {
          channel.tag_validation = {
            classes: "no-email",
            rule:
              '^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$'
          };
        } else if (channel.channel == "telegram") {
          channel.tag_validation = {
            classes: "no-numbers",
            rule: "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$" //"^([0-9]*)$"
          };
        }
        // get tags from the object
        let data_source =
          source && source.length && i < source.length
            ? source[i].data_source
            : channel.data_source || [];
        for (var name in data_source) {
          let group = JSON.parse(JSON.stringify(channel));
          let attr = data_source[name].attr;
          // it will be used to save on portal data:
          group.data_source = name;
          group.attr = attr;
          // ui configuration;
          group.name = data_source[name].text || "name?";
          group.tag_input_label = data_source[name].title || "title?";
          if (
            entry &&
            "portal_data" in entry &&
            entry.portal_data &&
            name in entry.portal_data
          ) {
            group.tags =
              (entry &&
                entry.portal_data &&
                entry.portal_data[name] &&
                entry.portal_data[name][attr]) ||
              [];
          } else {
            group.tags = [];
          }
          groups.push(group);
        }
      }
      self.$set(self, "groups", groups);
    },
    noData() {
      this.no_data = true;
      this.$emit("noData");
    },
    newConnectorService(newItem) {
      let self = this;
      let service = new ConnectorService();
      service.filter = function(items) {
        if (
          newItem &&
          self.contract &&
          self.contract.allowed_model_connectors
        ) {
          if (
            self.outOfQuota(
              self.contract.maximum_connectors,
              self.contract.registered_connectors
            )
          ) {
            return (items || []).filter((i) => i.base_model);
          }
        }
        return items;
      };
      return service;
    },
    close(action) {
      // if (action == "remove") {
      //   let vpath = this.$router.history.current.path.split("/");
      //   vpath.splice(this.isMQTT && this.$options.name == "FormData" ? -4 : -2);
      //   // replace current history state with a valid parent form path
      //   history.replaceState(history.state, null, vpath.join("/"));
      //   this.$router.push(vpath.join("/"));
      // } else {
      //   this.$router.back();
      // }
      this.$router.back();
    },
    onRemoved(id) {
      // Method interface - Child implementation
    }
  },
  created() { }
};
</script>
<style>
.popover {
  font-size: 1.2rem;
}
.popover-content {
  padding: 0.5rem 1rem;
  text-align: center;
}
</style>
