var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"toggle noselect",class:_vm.disabled ? 'disabled' : '',style:({
    fontSize: _vm.fontSize + 'pt',
    color: _vm.color,
    border: _vm.border || '1px solid #dfdfdf',
    padding: _vm.padding || '2px 8px',
    whiteSpace: 'nowrap'
  }),on:{"click":_vm.onClick}},[_c('i',{staticClass:"fa",class:_vm.cls}),(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }