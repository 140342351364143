<template>
  <input
    ref="inp"
    :pattern="customPattern"
    type="text"
    @focus="setFocus(true, $event)"
    @blur="setFocus(false, $event)"
    @change="onChange"
    @keydown="onKeyDown($event)"
    :value="iValue"
    @input="setValue($event)"
    enterkeyhint="done"
  />
</template>

<script>
export default {
  name: "InputNumber",
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: () => ""
    },
    focus: {
      type: Boolean,
      default: false,
      required: false
    },
    customPattern: {
      type: String,
      required: false,
      default: () => "\d.-"
    },
    saveOnTab: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      iValue: this.value,
      original: this.value,
      hasFocus: false
    };
  },
  watch: {
    value(n) {
      this.iValue = n;
    }
  },
  methods: {
    setValue($event) {
      if (this.hasFocus) {
        let value = $event.target.value.replace(/,/g, ".");
        if (this.isValid(value)) {
          this.iValue = value;
          this.notify();
        } else {
          var inputEvent = document.createEvent("Event");
          inputEvent.initEvent("input", true, true);
          event.target.value = event.target._value; // update the content of the element
          event.target.dispatchEvent(inputEvent); // trigger new event to update the model on vue event chain
        }
      }
    },
    // since it is using $el can not cache result
    isValid(value) {
      var test =
        typeof value !== "undefined" ? value : this.$el && this?.$el.value;
      test = (test + "").trim();
      if (this.customPattern != "\d.-") {
        let matches = test.match("^"+this.customPattern+"$");
        return test == "" || matches != null;  
      }
      return test == "" || test.match(/^-?([0-9]\d*(\.\d*)?)?$/i) != null;
    },
    setFocus(hasFocus, event) {
      this.hasFocus = hasFocus;
      if (this.$el) {
        this.$emit(this.hasFocus ? "focus" : "blur", event);
        if (this.hasFocus) {
          this.iValue = `${this.value}`.replace(/[^\d.-]/g, "");
        }
      }
    },
    notify() {
      if (this.isValid()) {
        this.$emit("input", this.iValue);
      }
    },
    onKeyDown(e) {
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
      if (
        e.code == "Enter" ||
        e.code == "NumpadEnter" ||
        e.key == "Enter" ||
        e.key == "NumpadEnter"
      ) {
        if (this.isValid()) {
          this.$emit("keydown", e);
        }
        e.preventDefault();
        e.stopPropagation();
      } else if (e.code == "Tab" || e.key == "Tab") {
        if ((this.$utils.isMobile() || this.saveOnTab) && this.isValid()) {
          this.$emit("keydown", e);
          e.preventDefault();
          e.stopPropagation();
        }
      } else if (e.code == "Escape" || e.key == "Escape") {
        this.$emit("keydown", e);
        e.preventDefault();
        e.stopPropagation();
      } else if (e.code == "ArrowUp" || e.key == "ArrowUp") {
        if (this.$attrs.step !== undefined) {
          this.$emit("inc", 1);
          e.preventDefault();
          e.stopPropagation();
        }
      } else if (e.code == "ArrowDown" || e.key == "ArrowDown") {
        if (this.$attrs.step !== undefined) {
          this.$emit("inc", -1);
          e.preventDefault();
          e.stopPropagation();
        }
      }
    },
    onChange(e) {
      this.$emit("change", e);
    }
  },
  mounted() {
    if (this.focus) {
      this.$nextTick(() => {
        if (this?.$refs?.inp?.focus) {
          this.$refs.inp.focus();
        }
      });
    }
  }
};
</script>
