import { pt, en } from "./forms";

// prettier-ignore
export default {
  pt: {
    hints: {
      process_area: "Área de processo a qual pertence o conector",
      serial_number: "Número serial do hardware do conector",
      serial_number_failover: "Permite definir um Número de Série secundário para o conector caso o Hardware do mesmo seja substituido",
      serial_number_secondary: "Número de Série secundário do conector. Ele substituirá o primário caso se conecte ao Portal",
      name: "Nome para identificação do {item}",
      description: "Descrição para identificação do {item} no portal",
      model: "Modelo do conector que acessará o Portal de Telemetria",
      optimized: "Habilita&nbsp;a&nbsp;otimização&nbsp;de&nbsp;frames&nbsp;que&nbsp;serão enviados ao conector ou envia um frame para cada dado",
      standard_device: "Dispositivo padrão do conector",
      device_address: "Informe o endereço deste dispositivo",
      is_hi_device: "Dispositivo&nbsp;fabricado pela HI Tecnologia",
      timezone: "Fuso horário aonde está localizado o conector",
      token: "Crie tokens para acessar remotamente o conector",
      protocol: "Protocolo utilizado para estabelecer a conexão com o Portal",
      datetime_update: "Atualização&nbsp;automática&nbsp;da data/hora do conector",
      custom_view: "Personalize a forma como os dados deste conector serão exibidos no dashboard",
      custom_view_history: "Personalize a forma como os dados deste conector serão exibidos no painel historico",
      redefine_custom_view: "Remove as modificações atuais e restaura a configuração original. Essa ação não pode ser desfeita.",
      software: 'Permite o acesso remoto a partir de um software',
      equipamento: 'Permite&nbsp;o&nbsp;acesso&nbsp;a&nbsp;um&nbsp;conector&nbsp;via ethernet quando não há recursos de acesso ao Portal',
      location: 'Texto que identifica a localização do conector',
      address: 'Define a localização do conector no mapa (depende do tipo de plano)',
      enables_connector_models: "Habilita o como modelo. Um conector modelo facilita o gerenciamento de equipamentos similares, centralizando a configuração",
      apply_changes_to_instances: "Aplicando alterações, instâncias baseadas neste modelo serão atualizadas com os valores definidos aqui",
      inherit_template_settings: "Herdar de um modelo, fara com que este conector assim como seus recursos sejam automaticamente atualizados quando o modelo for alterado",
      public_dashboard: "Crie links de acesso publico à telas deste conector"
    },
    error_labels: pt
  },
  en: {
    hints: {
      process_area: "Process area to which the connector belongs",
      serial_number: "Serial number of the connector hardware",
      serial_number_failover: "It allows to assign a Serial Number for the connector if its Hardware is replaced.",
      serial_number_secondary: "Connector's secondary Serial Number. It will replace the primary when connecting to the Portal",
      name: "Name to identify the {item} in the portal",
      description: "Description for {item} identification on the portal",
      model: "The model of connector which is going to access the Telemetria Portal",
      optimized: "Enabled: frames sent to the connector are optimized\nDisabled: frames sent to the connector are send data by data",
      standard_device: "Connector standard device",
      device_address: "Type here the device address",
      is_hi_device: "Device&nbsp;manufactured by HI Tecnologia",
      timezone: "Timezone which the connector is located",
      token: "Create tokens to remotely access the connector",
      protocol: "Protocol to be used to establish a connection with the Portal",
      datetime_update: "Automatic update of connector's date/time",
      custom_view: "Personalize the data display of this connector on the dashboard",
      custom_view_history: "Personalize the data display of this connector on the history data panel",
      redefine_custom_view: "Remove current changes and restore original configuration. This action cannot be undone.",
      software: 'Allows remote access from a software',
      equipamento: 'Allows remote access to a connector via ethernet when there are no resources to access the Portal',
      location: 'Text that identifies the connector location',
      address: 'Defines the connector map location (depends on plan type)',
      enables_connector_models: "Enable the use of models of connector. A connector model makes management easier once it centralizes the configuration of similar connectors",
      apply_changes_to_instances: "By applying changes, instances based on this model will be updated",
      inherit_template_settings: "Inherit from a model, will make this connector and its resources to be automatically updated when the model is changed",
      public_dashboard: "Create public access URLs to this connector"
    },
    error_labels: en
  },
};
