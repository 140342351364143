<template>
  <div class="duration-field">
    <div class="field-container">
      <div v-for="field in fields" :key="field.name">
        <div class="form-group form-group-sm">
          <input
            type="number"
            class="form-control text-center"
            inc="1"
            min="0"
            v-model="unit(field.name).value"
            :ref="field.name"
            :max="field.max"
            :disabled="disabled"
            @focus="onFocus($event)"
            @blur.stop="onBlur($event)"
            @keydown="onKeyDown($event)"
          />
          <div :class="legendClass" @click.stop.prevent="focusAt('days')">
            {{ $tc(unit(field.name).title, 2) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDuration from "@/components/input-duration.vue";
export default {
  name: "DataValueInputDuration",
  extends: InputDuration
};
</script>

<style scoped>
.duration-field {
  position: relative;
  z-index: 2;
  margin: auto;
  width: 100%;
  margin-bottom: 0;
  height: 30px;
  line-height: 1.5;
  font-size: 12px;
  max-width: 254px;
  max-height: 30px;
}

.field-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  height: inherit;
}

.field-container > div {
  flex-grow: 1;
}

.form-group {
  position: relative;
}

/* .form-group > input.form-control {
  padding: 8px 0 0 0;
} */

.form-group > input.form-control {
  min-width: 42px;
  padding: 10px 0 0 0;
  border-radius: 0;
}

.form-group > input.form-control:focus {
  border-color: #d2d6de;
}

div.legend {
  position: absolute;
  top: -1px;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 12pt;
  overflow: hidden;
  z-index: 3;
}

div.legend-2 {
  font-size: 80%;
}

div.legend-3 {
  font-size: 75%;
}

div.legend-4 {
  font-size: 70%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
