<template>
  <div id="root">
    <DropdownButton
      type="danger"
      @click="clear()"
      :disabled="loading"
      v-if="target != 'alarm'"
      data-testid="all-button"
    >
      {{ loading ? $t("removing") : $t("clear_history") }}
      <template #options>
        <li v-if="['connector', 'device'].includes(target)">
          <a
            href="#"
            @click.prevent="clear('data')"
            data-testid="data-button"
            >{{ $t("data_only") }}</a
          >
        </li>
        <li>
          <a
            href="#"
            @click.prevent="clear('alarm')"
            data-testid="alarms-button"
            >{{ $t("alarms_only") }}</a
          >
        </li>
      </template>
    </DropdownButton>
    <BaseButton
      v-else
      type="danger"
      @click="clear()"
      :disabled="loading"
      data-testid="all-button"
    >
      {{ loading ? $t("removing") : $t("clear_history") }}
    </BaseButton>
    <Tooltip
      id="hint-text"
      :title="$t(`hints.clear_history.${target}`)"
      data-testid="tooltip"
    />
    <Modal ref="modal" :title="$t('are_you_sure')" data-testid="modal">
      <template #content>
        <div class="alert alert-warning">
          <Icon name="fa fa-warning" />&nbsp;
          <span v-html="modalContent"></span>
        </div>
        <br />
        <form id="clear-history-form" ref="form" autocomplete="off">
          <input
            autocomplete="off"
            name="hidden"
            type="text"
            style="display: none;"
          /><!-- important to prevent autocomplete -->
          <div class="form-group">
            <strong>{{ $t("datetime_filter_type") }}</strong
            ><br />
            <label for="date_filter_mode_cutting" class="label-radio">
              <input
                type="radio"
                v-model="dateFilterMode"
                name="date_filter_mode"
                id="date_filter_mode_cutting"
                value="cutting"
              />
              {{ $t("cutting_datetime") }}
              <Tooltip :title="$t('hints.cutting_datetime')" />
            </label>
            &nbsp;
            <label for="date_filter_mode_period" class="label-radio">
              <input
                type="radio"
                v-model="dateFilterMode"
                name="date_filter_mode"
                id="date_filter_mode_period"
                value="period"
              />
              {{ $t("period_datetime") }}
              <Tooltip :title="$t('hints.period_datetime')" />
            </label>
            &nbsp;
            <label for="date_filter_mode_all" class="label-radio">
              <input
                type="radio"
                v-model="dateFilterMode"
                name="date_filter_mode"
                id="date_filter_mode_all"
                value="all"
              />
              {{ $t("no_datetime") }}
              <Tooltip :title="$t('hints.no_datetime')" />
            </label>
            <br />
          </div>
          <div class="form-group" v-if="dateFilterMode == 'cutting'">
            <label for="cutting-datetime"> {{ $t("cutting_datetime") }} </label
            ><br />
            <DatetimePicker
              id="cutting-datetime"
              v-model="cutting_datetime"
              data-testid="cutting-datetime"
            />
          </div>
          <div class="form-group" v-if="dateFilterMode == 'period'">
            <label for="start-datetime"> {{ $t("start_datetime") }} </label
            ><br />
            <DatetimePicker
              id="start-datetime"
              v-model="start_datetime"
              data-testid="start-datetime"
            />
            <br />
            <label for="end-datetime"> {{ $t("end_datetime") }} </label><br />
            <DatetimePicker
              id="end-datetime"
              v-model="end_datetime"
              data-testid="end-datetime"
            />
          </div>
          <div class="form-group">
            <label for="reason"
              >{{ $t("reason") }} <Tooltip :title="$t('hints.reason')" />
            </label>
            <textarea
              class="form-control"
              id="reason"
              v-model="reason"
              placeholder="Motivo"
              rows="3"
              required
              data-testid="reason"
            ></textarea>
          </div>
        </form>
      </template>
      <template #footer>
        <BaseButton data-dismiss="modal" data-testid="cancel-button">{{
          $t("cancel")
        }}</BaseButton>
        <BaseButton
          type="danger"
          @click="send"
          data-testid="remove-button"
          :disabled="!isValid"
        >
          {{ $t("remove") }}
        </BaseButton>
      </template>
    </Modal>
  </div>
</template>

<script>
import messages from "@/i18n/clear-history";

import BaseButton from "@/components/base/buttons/base-button";
import DropdownButton from "@/components/base/buttons/dropdown-button";
import Tooltip from "@/components/tooltip";
import Modal from "@/components/widgets/modal";
import Icon from "@/components/icons/icon.vue";
import DatetimePicker from "@/components/widgets/datetime-picker";

import HistoryService from "@/services/history";

export default {
  name: "ClearHistory",
  i18n: { messages },
  components: {
    BaseButton,
    DropdownButton,
    Tooltip,
    Modal,
    Icon,
    DatetimePicker
  },
  props: {
    target: {
      required: false,
      type: String,
      default: "connector"
    },
    targetId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      modalTrigger: null,
      howMany: 2, // quantity to customize pluralized messaged. 1 = just data or alarm | 2 = both
      resourceText: "",
      resource: "",
      dateFilterMode: "cutting",
      cutting_datetime: "",
      start_datetime: "",
      end_datetime: "",
      reason: "",
      loading: false
    };
  },
  watch: {
    dateFilterMode(n, o) {
      this.cutting_datetime = "";
      this.start_datetime = "";
      this.end_datetime = "";
    }
  },
  computed: {
    modalContent() {
      return this.$tc(`awareness_message.${this.target}`, this.howMany, {
        resource: this.resourceText
      });
    },
    isValid() {
      if (this.dateFilterMode == "all") {
        return this.reason.trim() != "";
      } else if (this.dateFilterMode == "cutting") {
        return this.reason.trim() != "" && this.cutting_datetime != "";
      } else if (this.dateFilterMode == "period") {
        return (
          this.reason.trim() != "" &&
          this.start_datetime != "" &&
          this.end_datetime != ""
        );
      }
      return false;
    }
  },
  methods: {
    dateStringToISO(value) {
      return value ? moment(value).utc().format("YYYY-MM-DDTHH:mm:ss") : "";
    },
    clear(resource) {
      switch (resource) {
        case "data":
          this.resourceText = this.$tc("data", 2).toLowerCase();
          this.resource = "data";
          this.howMany = 1;
          break;
        case "alarm":
          this.resourceText = this.$tc("alarm", 2).toLowerCase();
          this.resource = "alarms";
          this.howMany = 1;
          break;
        default:
          this.howMany = 2;
          this.resource = "all";
      }
      $(this.$refs.modal.$el).modal("show");
    },
    async send() {
      if (this.$refs.form.reportValidity()) {
        $(this.$refs.modal.$el).modal("hide");
        let service = new HistoryService();
        try {
          this.loading = true;
          await service.clear({
            id: this.targetId,
            target: this.target,
            history_type: this.resource,
            reason: this.reason,
            cutting_datetime:
              (this.cutting_datetime &&
                `${this.dateStringToISO(this.cutting_datetime)}.999999`) ||
              "",
            start_datetime:
              (this.start_datetime &&
                `${this.dateStringToISO(this.start_datetime)}.000000`) ||
              "",
            end_datetime:
              (this.end_datetime &&
                `${this.dateStringToISO(this.end_datetime)}.999999`) ||
              ""
          });

          this.$swal({
            title: this.$t("clean"),
            text: this.$t("history_cleared"),
            icon: "success"
          });
        } catch (error) {
          this.$swal({
            title: this.$t("an_error_has_occurred"),
            text: error || this.$t("history_not_cleared"),
            icon: "error"
          });
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.label-radio {
  font-weight: 400;
}

#hint-text {
  font-size: 2rem;
  margin-left: 1rem;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
