<script>
const SEC_MULT = { seconds: 1, minutes: 60, hours: 3600, days: 86400 };

export default {
  name: "InputDuration",
  props: {
    showDays: {
      type: Boolean,
      required: false,
      default: false
    },
    showSeconds: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: [Number, String],
      required: false,
      default: 0
    },
    focus: {
      type: Boolean,
      default: false,
      required: false
    },
    userUnit: {
      type: String,
      default: "seconds",
      required: false
    }
  },
  data: () => ({
    days: {
      enabled: false,
      value: 0,
      title: "day",
      max: 365,
      next: "hours"
    },
    hours: {
      enabled: true,
      value: 0,
      title: "hour",
      max: 24,
      next: "minutes"
    },
    minutes: {
      enabled: true,
      value: 0,
      title: "minute",
      max: 60,
      next: "seconds"
    },
    seconds: {
      enabled: true,
      value: 0,
      title: "second",
      max: 60,
      next: ""
    },
    total: 0, // total of seconds
    hasFocus: false
  }),
  computed: {
    legendClass() {
      return this.units.length <= 2
        ? "legend legend-2"
        : this.units.length == 3
        ? "legend legend-3"
        : "legend legend-4";
    },
    units() {
      const units = ["seconds", "minutes", "hours", "days"].filter(
        (name) => this[name].enabled
      );
      return units;
    },
    fields() {
      return ["days", "hours", "minutes", "seconds"]
        .filter((name) => {
          return this.unit(name).enabled;
        })
        .map((name, ix) => {
          let field = { ...this.unit(name) };
          field.max = ix == 0 ? 999 : field.max;
          field.name = name;
          return field;
        });
    },
    disabled() {
      return this?.$attrs?.disabled || false;
    }
  },
  watch: {
    showDays: {
      handler(n) {
        this.days.enabled = n;
      },
      immediate: true
    },
    showSeconds: {
      handler(n) {
        this.seconds.enabled = n;
      },
      immediate: true
    },
    total(n) {
      let value = parseInt(n || 0) / (SEC_MULT[this.userUnit] || 1);
      this.$emit("input", value);
    },
    hasFocus(n) {
      if (this.$el) {
        this.$emit(n ? "focus" : "blur");
      }
    },
    value: {
      handler(n) {
        if (/\D/g.test(n)) return; // not a number
        let value = parseInt(n || 0) * (SEC_MULT[this.userUnit] || 1);
        if (value !== this.total) {
          this.parse(value);
        }
      },
      immediate: true
    }
  },
  methods: {
    unit(name) {
      return this[name];
    },
    focusAt(name) {
      if (name in this.$refs && this.$refs[name][0]) {
        this.$refs[name][0].focus();
        return true;
      }
      return false;
    },
    parse(value) {
      if (value !== undefined) {
        this.total = parseInt(value, 10);
        // distribute it
        let total = this.total;
        this.seconds.value = total % 60;
        total = Math.floor(total / 60);
        this.minutes.value = total % 60;
        total = Math.floor(total / 60);
        if (this.showDays) {
          this.hours.value = total % 24;
          this.days.value = Math.floor(total / 24);
        } else {
          this.hours.value = total;
          this.days.value = 0;
        }
      } else {
        // local update (dom)
        let total =
          parseInt(this.seconds.value, 10) * SEC_MULT.seconds +
          parseInt(this.minutes.value, 10) * SEC_MULT.minutes +
          parseInt(this.hours.value, 10) * SEC_MULT.hours +
          parseInt(this.days.value, 10) * SEC_MULT.days;
        if (this.total != total) {
          this.parse(total);
        }
      }
    },
    setFocus(value) {
      this.hasFocus = value;
    },
    onFocus($event) {
      this.setFocus(true);
    },
    onBlur() {
      this.parse();
    },
    onKeyDown(e) {
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
      if (
        e.code == "Enter" ||
        e.code == "NumpadEnter" ||
        e.key == "Enter" ||
        e.key == "NumpadEnter"
      ) {
        this.parse();
        this.$nextTick(() => {
          this.$emit("keydown", e);
        });
        e.preventDefault();
        e.stopPropagation();
      } else if (e.code == "Tab" || e.key == "Tab") {
        if (e.shiftKey) return;
        if (this.$utils.isMobile() || this.saveOnTab) {
          for (var name in this.$refs) {
            if (e.target == this.$refs[name][0]) {
              let unit = this.unit(name);
              if (unit.next && this.focusAt(unit.next)) {
                this.parse();
                e.preventDefault();
                e.stopPropagation();
              } else {
                this.$nextTick(() => {
                  this.$emit("keydown", e);
                });
              }
              return;
            }
          }
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  },
  mounted() {
    //this.parse(parseInt(this.value || 0));
    if (this.focus) {
      this.$nextTick(() => {
        this.focusAt("days") || this.focusAt("hours");
      });
    }
  }
};
</script>
