<template>
  <div class="form-inline me">
    <div class="form-group form-group-sm" style="white-space: nowrap;">
      <label for="editor-rotation">
        {{ $t("synoptic.rotation") }}
      </label>
      <div class="input-group input-group-sm">
        <input
          class="form-control text-center"
          type="number"
          id="editor-rotation"
          v-model="rotation"
          data-testid="rotation"
          style="min-width:75px"
        />
        <div class="input-group-addon btn" @click="rotation = 0" title="0">
          <span class="fa fa-close"></span>
        </div>
        <div
          class="input-group-addon btn"
          @click="rotation -= 90"
          title="-90°"
          data-testid="rotate-left"
        >
          <span class="fa fa-rotate-left"></span>
        </div>
        <div
          class="input-group-addon btn"
          @click="rotation += 90"
          title="+90°"
          data-testid="rotate-right"
        >
          <span class="fa fa-rotate-right"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SlidableDirective from "@/directives/slidable";

export default {
  name: "ControlRotation",
  directives: { slidable: SlidableDirective },
  props: {
    control: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noRecord: false
    };
  },
  computed: {
    rotation: {
      get() {
        return this.control.synopticComponent.rotation || 0;
      },
      set(value) {
        value = parseInt(value);
        value = value >= 360 ? value - 360 : value < 0 ? 360 + value : value;
        let control = JSON.parse(JSON.stringify(this.control));
        control.synopticComponent.rotation = value;
        this.updateControl({
          id: this.control.id,
          control,
          noRecord: this.noRecord
        });
      }
    }
  },
  methods: {
    update(direction) {
      this.rotation += direction;
    },
    stopRecording() {
      this.noRecord = true;
    },
    resumeRecording() {
      this.noRecord = false;
      this.updateControl({
        id: this.control.id,
        control: this.control,
        forceRecord: true
      });
    },
    ...mapActions("synoptic", ["updateControl"])
  }
};
</script>

<style scoped>
#editor-rotation::-webkit-outer-spin-button,
#editor-rotation::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

#editor-rotation {
  -moz-appearance: textfield;
}

label {
  margin-right: 5px;
}

.me {
  margin: 1em 0 !important;
}

.me .form-control,
.me .btn {
  height: 25px;
  padding: 3px 7px;
}

.me .form-control {
  border-right: none;
}

.me .btn {
  padding: 2px 7px 0px 7px;
}

.me .btn:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
