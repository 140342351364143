var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"me"},[_c('div',{staticClass:"title",attrs:{"data-testid":"title"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" "),_c('div',{staticClass:"unity",attrs:{"data-testid":"unit"}},[_vm._v("("+_vm._s(_vm.unit)+")")]),(_vm.lockables && _vm.lockables.length > 0)?_c('span',{staticClass:"btn btn-xs",attrs:{"data-testid":"lock","title":_vm.$t(_vm.modeTitle)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.nextMode.apply(null, arguments)}}},[_c('i',{class:[_vm.modeIcon, _vm.modeColor]})]):_vm._e()]),_c('div',{staticClass:"fields"},[_c('div',{staticClass:"field"},[_c('div',{class:[
          'title',
          _vm.canLock(0) && _vm.locked ? _vm.modeColor : '',
          _vm.disabled[0] ? 'disabled' : ''
        ],attrs:{"data-testid":"field-label"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.labels[0]))+" ")]),_c('input',{ref:"v0",attrs:{"type":"number","disabled":_vm.disabled[0]},domProps:{"value":_vm.value[0]},on:{"input":function($event){return _vm.dispatch(0, $event.target.value)},"focus":function($event){_vm.editing = true},"blur":function($event){_vm.editing = false}}})]),_c('div',{staticClass:"field"},[_c('div',{class:[
          'title',
          _vm.canLock(1) && _vm.locked ? _vm.modeColor : '',
          _vm.disabled[1] ? 'disabled' : ''
        ],attrs:{"data-testid":"field-label"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.labels[1]))+" ")]),_c('input',{ref:"v1",attrs:{"type":"number","disabled":_vm.disabled[1]},domProps:{"value":_vm.value[1]},on:{"input":function($event){return _vm.dispatch(1, $event.target.value)},"focus":function($event){_vm.editing = true},"blur":function($event){_vm.editing = false}}})]),_c('div',{staticClass:"field"},[_c('div',{class:[
          'title',
          _vm.canLock(2) && _vm.locked ? _vm.modeColor : '',
          _vm.disabled[2] ? 'disabled' : ''
        ],attrs:{"data-testid":"field-label"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.labels[2]))+" ")]),_c('input',{ref:"v2",attrs:{"type":"number","disabled":_vm.disabled[2]},domProps:{"value":_vm.value[2]},on:{"input":function($event){return _vm.dispatch(2, $event.target.value)},"focus":function($event){_vm.editing = true},"blur":function($event){_vm.editing = false}}})]),_c('div',{staticClass:"field"},[_c('div',{class:[
          'title',
          _vm.canLock(3) && _vm.locked ? _vm.modeColor : '',
          _vm.disabled[3] ? 'disabled' : ''
        ],attrs:{"data-testid":"field-label"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.labels[3]))+" ")]),_c('input',{ref:"v3",attrs:{"type":"number","disabled":_vm.disabled[3]},domProps:{"value":_vm.value[3]},on:{"input":function($event){return _vm.dispatch(3, $event.target.value)},"focus":function($event){_vm.editing = true},"blur":function($event){_vm.editing = false}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }