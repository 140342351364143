/**
 * Slidable Directive: allows a granular increment
 * to a value with a slider effect on the target element
 */

import { debounceTime } from "@/utils";

let bindedElements = {},
  bindedEl;

function beginSlide(e) {
  bindedEl = bindedElements[e.target.dataset.uid];
  bindedEl.moving = true;
  document.body.style.cursor = e.target.style.cursor;
  if (typeof bindedEl.onBeginSlide == "function") bindedEl.onBeginSlide(e);
}

function update(e) {
  if (bindedEl?.moving && e[bindedEl.axis] != 0) {
    if (typeof bindedEl.onUpdate == "function")
      bindedEl.onUpdate(e[bindedEl.axis]);
  }
}

function endSlide(e) {
  if (bindedEl) {
    bindedEl.moving = false;
    if (typeof bindedEl.onEndSlide == "function") bindedEl.onEndSlide(e);
    bindedEl = null;
  }
  document.body.style.cursor = "";
}

const delayedUpdate = debounceTime(1, update);

export default {
  bind(el, { value }) {
    let onUpdate,
      onBeginSlide,
      onEndSlide,
      axis = "movementX",
      uid;

    switch (typeof value) {
      case "function":
        onUpdate = value;
        break;
      case "object":
        onUpdate = value.onUpdate;
        onBeginSlide = value.onBeginSlide;
        onEndSlide = value.onEndSlide;
        axis = value.vertical ? "movementY" : "movementX";
        if (
          typeof onUpdate != "function" &&
          typeof onBeginSlide != "function" &&
          typeof onEndSlide != "function"
        )
          return;
        break;
      default:
        return;
    }
    el.style.cursor = value.vertical ? "ns-resize" : "ew-resize";
    el.style.userSelect = "none";

    uid = Math.round(Math.random() * 10000);
    el.dataset.uid = uid;
    bindedElements[uid] = {
      onUpdate,
      onBeginSlide,
      onEndSlide,
      axis,
      moving: false
    };

    el.addEventListener("mousedown", beginSlide);
    document.body.addEventListener("mousemove", delayedUpdate);
    document.body.addEventListener("mouseup", endSlide);
  },
  unbind(el) {
    el.removeEventListener("mousedown", beginSlide);
    document.body.removeEventListener("mousemove", delayedUpdate);
    document.body.removeEventListener("mouseup", endSlide);

    delete bindedElements[el.dataset.uid];
  }
};
