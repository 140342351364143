import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";

Vue.use(VueResource);

export default class HistoryService {
  clear(options) {
    return new Promise((resolve, reject) => {
      let resource = "connectors";

      switch (options.target) {
        case "connector":
          resource = "connectors";
          break;
        case "device":
          resource = "devices";
          break;
        case "data":
          resource = "data";
          break;
        case "alarm":
          resource = "alarms";
          break;
      }

      if (typeof options.id != "number") throw new Error("invalid id");
      let id = options.id;
      let url = `${resource}/${id}/clear_history/`;
      let payload = {
        history_type: options.history_type,
        reason: options.reason,
        cutting_datetime: options.cutting_datetime || null,
        start_datetime: options.start_datetime || null,
        end_datetime: options.end_datetime || null
      };

      Vue.http.post(url, payload, new Auth().requestOptions()).then(
        (response) => {
          if (response.ok) resolve();
          else reject((response.body || {}).detail || null);
        },
        (response) => reject((response.body || {}).detail || null)
      );
    });
  }
}
