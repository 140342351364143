<template>
  <section>
    <div class="modal fade in" ref="alarmNotificationForm">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" v-if="isReady">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ title }}</h4>
          </div>
          <div class="modal-body" style="min-height: 350px;">
            <div class="overlay overlay-local" v-if="busy">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
            <EquipmentAlarmConfigurationTable
              v-bind:alarms="alarms"
              v-bind:panelOptions="{ showLine: false }"
              v-bind:full="false"
              :hiddenFields="['notification']"
            >
            </EquipmentAlarmConfigurationTable>
            <NotificationUserInputForm
              v-bind:displayOrder="false"
              v-bind:value="groups"
              v-model="groups"
              v-bind:maxGroups="5"
              v-bind:allowNewGroup="false"
            >
            </NotificationUserInputForm>
          </div>
          <div class="modal-footer">
            <NotificationInlineForm
              v-bind:groups="groups"
              v-bind:timeout="timeout"
              v-bind:save="save"
              v-on:submit="validate"
              v-on:cancel="cancel"
            >
            </NotificationInlineForm>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import EquipmentAlarmConfigurationTable from "@/components/equipment-alarm-configuration-table";
import NotificationUserInputForm from "@/components/notification-user-input-form";
import NotificationInlineForm from "@/components/notification-inline-form";
import MixinAlert from "@/project/mixin-alert.js";
import EquipmentAlarm from "@/services/equipment-alarm.js";
import AlarmService from "@/services/alarm.js";
export default {
  name: "EquipmentAlarmNotificationForm",
  components: {
    NotificationUserInputForm,
    EquipmentAlarmConfigurationTable,
    NotificationInlineForm
  },
  props: {
    alarms: {
      type: Array,
      required: false,
      default: () => []
    },
    // if there are groups on equipment get it by default
    defaultOptions: {
      type: Object,
      required: false,
      default: () => ({ timeout: 300000, groups: [] })
    },
    // Save the payload here or send back to the parent?
    save: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  mixins: [MixinAlert],
  data: function() {
    return {
      busy: false,
      isReady: true,
      groups: [],
      timeout: 0
    };
  },
  computed: {
    title: function() {
      return this.$t("notification_configuration");
    },
    alarm: function() {
      let alarm = JSON.parse(
        JSON.stringify(
          (this.alarms && this.alarms.length && this.alarms[0]) || null
        )
      );
      return alarm;
    },
    isValid: function() {
      let self = this;
      return self.alarm != null;
    }
  },
  methods: {
    cancel: function() {
      if (this.save) {
        this.initData();
      } else {
        this.hide();
      }
    },
    hide: function() {
      $(this.$refs.alarmNotificationForm).modal("hide");
    },
    doSave(entry) {
      let self = this;
      self.busy = true;
      self.service
        .get(self.alarm.id, self.$store.getters["user/contract_id"])
        .then((alarm) => {
          if (alarm) {
            let payload = JSON.parse(JSON.stringify(alarm));
            payload.user_data = payload.user_data || {};
            payload.user_data.extended_properties =
              payload.user_data.extended_properties || {};
            payload.user_data.extended_properties.notifications =
              payload.user_data.extended_properties.notifications || {};
            payload.user_data.extended_properties.notifications = {
              timeout: entry.timeout,
              groups: self.groups.map(function(item) {
                return {
                  name: item.name,
                  users: item.tags.filter((i) => i.indexOf("@") > 0)
                };
              }),
              groups_telegram: self.groups.map(function(item) {
                return {
                  name: item.name,
                  users: item.tags.filter((i) => i.indexOf("@") < 0)
                };
              })
            };
            self.service.save(payload).then((ret) => {
              self.busy = false;
              if (self.validateSaveResponse(ret)) {
                self.$emit("refresh");
              }
              self.showAlert();
              self.hide();
            });
          }
        });
      return;
    },
    validate: function(entry) {
      let self = this;
      self.validatePrivileges("change", "NotificacoesEscrita").then((isOk) => {
        if (!isOk) {
          self.hide();
          return;
        }
        if (self.isValid) {
          if (self.save) {
            self.doSave(entry);
          }
          return;
        }
        self.hide();
      });
    },
    initData: function() {
      let self = this;
      self.isReady = false;
      let config = null;
      if (self.save) {
        // only treats the stored alarm if it will be saved here
        config =
          (self.alarm &&
            "user_data" in self.alarm &&
            self.alarm.user_data &&
            "extended_properties" in self.alarm.user_data &&
            self.alarm.user_data.extended_properties &&
            "notifications" in self.alarm.user_data.extended_properties &&
            self.alarm.user_data.extended_properties.notifications) ||
          null;
      }
      if (config) {
        let telegram_source = config.groups_telegram || null;
        self.groups = config.groups.map(function(i, ix) {
          var group = {
            name: i.name,
            tags: (("users" in i && i.users) || []).map(function(user) {
              return { text: user };
            }),
            fixed: false
          };
          if (telegram_source && ix < telegram_source.length) {
            let ii = telegram_source[ix];
            let tags = (("users" in ii && ii.users) || []).map(function(user) {
              return { text: user };
            });
            if (tags.length) {
              group.tags = group.tags.concat(tags);
            }
          }
          return group;
        });
        self.timeout = parseInt(config.timeout);
      } else {
        self.groups = self.defaultOptions.groups;
        self.timeout = self.defaultOptions.timeout;
      }
      self.$nextTick(function() {
        self.isReady = true;
      });
    }
  },
  created: function() {
    this.service = new AlarmService();
    this.initData();
  },
  mounted: function() {
    let self = this;
    $(self.$refs.alarmNotificationForm)
      .on("hidden.bs.modal", function() {
        self.showAlert(function() {
          self.$emit("hide");
        });
      })
      .on("shown.bs.modal", function() {
        self.$emit("show");
      })
      .modal("show");
  }
};
</script>
<style scoped></style>
